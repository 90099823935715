import React, { ChangeEvent, KeyboardEvent, useEffect } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import EditableTag from './EditableTag'
import { Input } from 'src/components/general/Input'
import { FormFieldSubtitle } from '../host/MatchmakingSettingsForm/styled'
import AlertManager from 'src/managers/AlertManager/AlertManager'

interface TagInputProps {
	placeholder?: string
	tags?: string[]
	onSubmit: (tags: string[]) => void
	disabled?: boolean
}

export const TagInput: React.FC<TagInputProps> = ({ placeholder, tags, onSubmit, disabled = false }) => {
	const [editableTags, setEditableTags] = React.useState<string[]>(tags ? tags : [])
	const [inputValue, setInputValue] = React.useState<string>('')

	useEffect(() => {
		if (tags) {
			setEditableTags(tags)
		} else {
			setEditableTags([])
		}
	}, [tags])

	const addValueToTags = () => {
		if (!inputValue || inputValue === '') {
			return
		}
		if (editableTags.includes(inputValue)) {
			AlertManager.message('This tag has already been added!', 'error')
			return
		}

		const updatedTags = [...editableTags]
		updatedTags.push(inputValue)
		setEditableTags(updatedTags)
		setInputValue('')
		onSubmit(updatedTags)
	}

	const handleTagDelete = (index: number) => () => {
		let copiedEditableTags = _.cloneDeep(editableTags)
		copiedEditableTags.splice(index, 1)
		setEditableTags(copiedEditableTags)
		onSubmit(copiedEditableTags)
	}

	const handleInputKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			addValueToTags()
		}
	}

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setInputValue(e.target.value)
	}

	return (
		<Container>
			<InputContainer>
				<Input
					disabled={disabled}
					placeholder={placeholder}
					value={inputValue}
					onKeyDown={handleInputKeydown}
					onChange={handleInputChange}
				/>
			</InputContainer>
			<StyledSubtitle>Press Enter or Return to create each tag.</StyledSubtitle>
			{editableTags.length > 0 && (
				<TagContainer>
					{editableTags.map((tag, index) => (
						<EditableTag
							key={tag}
							onDelete={disabled ? undefined : handleTagDelete(index)}
							disabled={disabled}
						>
							{tag}
						</EditableTag>
					))}
				</TagContainer>
			)}
		</Container>
	)
}

const StyledSubtitle = styled(FormFieldSubtitle)`
	margin-top: 4px;
`

const InputContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`

const Container = styled.div``

const TagContainer = styled.div`
	margin: 5px 0;
`

TagInput.defaultProps = {}
