import { InMemoryCache, makeVar } from '@apollo/client'
import { FeedPage, User } from 'src/__generated__/graphql'

export type AmbientUserState = Omit<User, 'posts'>
export const currentUser = makeVar<AmbientUserState | undefined>(undefined)

export enum ConnectionStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  RECONNECTING = 'RECONNECTING',
  RECONNECTED = 'RECONNECTED',
}

export enum APIAuthStatus {
  UNAUTHED = 'UNAUTHED',
  AUTHENTICATED = 'AUTHENTICATED',
  REFRESHING = 'REFRESHING',
  ERROR = 'ERROR'
}

export const apiAuthenticationStatus = makeVar<APIAuthStatus>(APIAuthStatus.UNAUTHED)
export const subscriptionConnectionStatus = makeVar<ConnectionStatus>(ConnectionStatus.DISCONNECTED)
export const websocketLastSeenSeverTime = makeVar<Date>(new Date)

/**
 * This is a container object for the feed cache.
 * 
 * NOTE: Apollo can only diff plain objects, arrays and scalars. You CANNOT
 * diff a map or set. Failing to follow this rule will result in the
 * cache not updating properly.
 */
interface FeedCache {
  posts: any[]
  remaining: number
  // Do not use undefined in the cache! It will cause a cache miss
  // because apollo sees "undefined" as "missing in cache".
  nextCursor: string | null
}

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        user: {
          // if we have already retrieved the user lists using the allUsers query
          // we can use that data
          read(_: any, { args, toReference }) {
            const a = args as { id: string }
            return toReference({
              __typename: 'User',
              id: a.id,
            })
          }
        },
        feed: {
          // We will store a copy of the feed for every combination of filters.
          // This is relatively efficient as the feed only stores references to posts
          keyArgs: [['filter', ['postSource', 'authorId', 'tagIds', 'sharedWithMe']], 'searchTerms'],

          /**
           * This merge function will run when a query is run. It will also execute 
           * when refetch (with merge = true) or when "fetchMore" is called.
           * 
           * It will merge the existing cache with the incoming data and store it in FeedCache
           * object for future reads.
           * 
           * The incoming data will be the previous cache value for the same args as defined
           * in the keyArgs above.
           * 
           * @param existing the existing cache from a previous query
           * @param incoming the incoming data from the new query
           * @returns 
           */
          merge(existing: FeedCache | null, incoming: FeedPage, { readField }): FeedCache {
            // figure out if the incoming data is newer or older than the existing data
            const oldestExistingPost = existing?.posts[existing.posts.length - 1]
            const oldestIncomingPost = incoming.posts[incoming.posts.length - 1]
            const result: FeedCache = {
              posts: existing?.posts.slice(0) ?? [],
              remaining: existing?.remaining ?? 0,
              nextCursor: existing?.nextCursor ?? null,
            }

            let incomingIsOlder = true
            if (oldestExistingPost && oldestIncomingPost) {
              const oldestExistingDate = new Date(readField('createdAt', oldestExistingPost) as string)
              const oldestIncomingDate = new Date(readField('createdAt', oldestIncomingPost) as string)
              incomingIsOlder = oldestExistingDate.getTime() > oldestIncomingDate.getTime()
            }

            if (incomingIsOlder) {
              // if the incoming data is older, we should overwrite the existing
              // remaining and nextCursor values
              result.remaining = incoming.remaining
              result.nextCursor = incoming.nextCursor ?? null
            }

            // deduplicate the posts in the array
            const dedupeMap = new Map<string, any>()
            // add incoming posts to the end so thay overwrite existing posts as we loop
            result.posts.push(...(existing?.posts.slice(0) ?? []))
            result.posts.push(...incoming.posts)
            result.posts.forEach(p => dedupeMap.set(readField('id', p)!, p))

            // convert map back to array
            const resultMapArray = Array.from(dedupeMap.values())

            // sort by createdAt and return
            result.posts = resultMapArray.sort((a, b) => {
              const aDate = new Date(readField('createdAt', a) as string)
              const bDate = new Date(readField('createdAt', b) as string)
              return bDate.getTime() - aDate.getTime()
            })

            return result
          }
        }
      }
    }
  }
})
