import { ApolloClient, HttpLink, split, from, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { Observable, getMainDefinition } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'
import { GRAPHQL_URL, GRAPHQL_WS_URL } from 'src/resources/constants'
import { setContext } from '@apollo/client/link/context'
import { RefreshTokenResponse, refreshAmbientAuthToken } from './api'
import rootStore from 'src/store/rootStore'
import { setAuthTokens } from 'src/store/slices/userSlice'
import { ConnectionStatus, cache, subscriptionConnectionStatus, websocketLastSeenSeverTime } from './graphql/localState'
import AlertManager from 'src/managers/AlertManager/AlertManager'

const MAX_RETRY_WAIT_MS = 5_000
export const KEEP_ALIVE_TTL_MS = 5_000

/**
 * This function is used to convert a promise to an observable in order to
 * use async/await in the errorLink
 * @param promise
 * @returns
 */
function promiseToObservable<T>(promise: Promise<T>) {
	return new Observable<T>((subscriber) => {
		promise.then(
			(value) => {
				if (subscriber.closed) {
					return
				}
				subscriber.next(value)
				subscriber.complete()
			},
			(err) => {
				subscriber.error(err)
			}
		)
	})
}

/**
 * Create an apollo client with the correct links
 * @returns
 */
export const createApolloClient = () => {
	// handle errors such as refreshing tokens
	// will retry requests (operations) that failed due to expired tokens
	const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
		if (
			graphQLErrors?.some((e) => (e.extensions?.http as object ?? {})['code'] === 401) ||
			graphQLErrors?.some((e) => e.message === 'Auth token expired') ||
			networkError?.message.includes('Auth token expired')
		) {
			const oldHeaders = operation.getContext().headers
			const token = rootStore.getState().user.feedToken!
			const refreshToken = rootStore.getState().user.refreshToken!

			return promiseToObservable<RefreshTokenResponse>(refreshAmbientAuthToken(token, refreshToken)).flatMap(
				(tokenData: RefreshTokenResponse) => {
					// update for current operation
					operation.setContext({
						headers: {
							...oldHeaders,
							authorization: tokenData.token
						}
					})

					// update for future operations
					rootStore.dispatch(setAuthTokens({ token: tokenData.token, refresh: tokenData.refreshToken }))

					// retry the request, returning the new observable
					return forward(operation)
				}
			)
		}
	})

	// handles http communication
	const httpLink = new HttpLink({
		uri: GRAPHQL_URL
	})

	// handles websocket communication for subscriptions
	const wsLink = new GraphQLWsLink(
		createClient({
			disablePong: true,
			keepAlive: KEEP_ALIVE_TTL_MS,
			url: () => GRAPHQL_WS_URL,
			// this is pulled from the official retry policy with an additional MAX added by me
			async retryWait(retries) {
				let retryDelay = 1000 * (2 ** retries)
				const minJitterMS = 300
				const jitter = Math.floor(Math.random() * 1000) + minJitterMS
				retryDelay = Math.min(retryDelay, MAX_RETRY_WAIT_MS) + jitter

				await new Promise((resolve) => setTimeout(resolve, retryDelay))
			},
			retryAttempts: Infinity,
			shouldRetry: () => {
				subscriptionConnectionStatus(ConnectionStatus.RECONNECTING)
				return true
			},
			connectionParams: () => {
				const token = rootStore.getState().user.feedToken
				return {
					authToken: token
				}
			},
			// keep track of connection status
			on: {
				pong: () => {
					websocketLastSeenSeverTime(new Date())
				},
				connecting: () => {
					if (subscriptionConnectionStatus() === ConnectionStatus.RECONNECTING) {
						AlertManager.message('Reconnecting...', 'info', MAX_RETRY_WAIT_MS * 2, 'reconnecting-websocket')
					}
				},
				connected: () => {
					// treat reconnection differently than initial connection
					if (subscriptionConnectionStatus() === ConnectionStatus.RECONNECTING) {
						AlertManager.dismiss('reconnecting-websocket')
						AlertManager.message('Connected', 'success', 2000, 'reconnecting-websocket')
						subscriptionConnectionStatus(ConnectionStatus.RECONNECTED)
					} else {
						subscriptionConnectionStatus(ConnectionStatus.CONNECTED)
					}
				},
				closed: (event: unknown) => {
					// if we are not trying to reconnect, just close the connection
					if (subscriptionConnectionStatus() !== ConnectionStatus.RECONNECTING) {
						subscriptionConnectionStatus(ConnectionStatus.DISCONNECTED)
					}
				}
			}
		})
	)

	// choose the correct transport based on operation. Subscription operations will use ws, all others http
	const splitLink = split(
		({ query }) => {
			const definition = getMainDefinition(query)
			return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
		},
		wsLink,
		httpLink
	)

	// Sets the auth header for each request
	const authLink = setContext(async (_, { headers }) => {
		const token = rootStore.getState().user.feedToken!

		return {
			headers: {
				...headers,
				authorization: token
			}
		}
	})

	const client = new ApolloClient({
		connectToDevTools: process.env.NODE_ENV === 'development',
		cache,
		link: from([authLink, errorLink, splitLink])
	})

	// store stage in local storage
	return client
}

/**
 * @returns a public apollo client that does not require authentication
 */
export function getPublicClient() {
	const publicApolloClient = new ApolloClient({
		uri: `${GRAPHQL_URL}/public`,
		cache: new InMemoryCache()
	})
	return publicApolloClient
}