import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Match } from 'src/models/match'
import { AssignmentStatus } from 'src/managers/SocketManager/types'
import { BreakoutRoomAssignmentInfo } from 'src/managers/ZoomSDKManager/helpers/assign/types'

export interface MatchRoundEndTime {
	endDateTimeISOString: string
	secondsRemaining: number
}

export type MatchmakingState = {
	matchmakingStarted: boolean
	matchmakingStarting: boolean // loading state for matchmaking
	previousMatchmakingEnding: boolean | null
	matchmakingEnding: boolean
	match?: Match
	matchRoundEndTime?: MatchRoundEndTime
	matchmakingAssignmentsInfo: BreakoutRoomAssignmentInfo
}

export const initialState: MatchmakingState = {
	matchmakingStarted: false,
	matchmakingStarting: false,
	matchmakingAssignmentsInfo: {
		status: AssignmentStatus.IDLE,
		shouldSaveTemplate: false
	},
	previousMatchmakingEnding: null,
	matchmakingEnding: false
}

export const matchmakingSlice = createSlice({
	name: 'matchmaking',
	initialState,
	reducers: {
		resetMatchmakingState: () => {
			return initialState
		},
		startMatchmaking: (state: MatchmakingState) => {
			return { ...state, matchmakingStarted: true }
		},
		stopMatchmaking: (state: MatchmakingState) => {
			return { ...state, matchmakingStarted: false }
		},
		setMatch: (state: MatchmakingState, action: PayloadAction<Match | undefined>) => {
			return {
				...state,
				match: action.payload
			}
		},
		setMatchRoundEndTime: (state: MatchmakingState, action: PayloadAction<MatchRoundEndTime | undefined>) => {
			return {
				...state,
				matchRoundEndTime: action.payload
			}
		},
		setMatchmakingStarting: (state: MatchmakingState, action: PayloadAction<boolean>) => {
			return {
				...state,
				matchmakingStarting: action.payload
			}
		},
		setMatchmakingAssignmentsInfo: (state: MatchmakingState, action: PayloadAction<BreakoutRoomAssignmentInfo>) => {
			return {
				...state,
				matchmakingAssignmentsInfo: action.payload
			}
		},
		resetMatchmakingAssignmentsInfo: (state: MatchmakingState) => {
			return {
				...state,
				matchmakingAssignmentsInfo: initialState.matchmakingAssignmentsInfo
			}
		},
		setMatchmakingEnding: (state: MatchmakingState, action: PayloadAction<boolean>) => {
			return {
				...state,
				previousMatchmakingEnding: state.matchmakingEnding,
				matchmakingEnding: action.payload
			}
		}
	}
})

export default matchmakingSlice

export const matchmakingReducer = matchmakingSlice.reducer
export const {
	resetMatchmakingState,
	startMatchmaking,
	stopMatchmaking,
	setMatch,
	setMatchRoundEndTime,
	setMatchmakingStarting,
	setMatchmakingAssignmentsInfo,
	resetMatchmakingAssignmentsInfo,
	setMatchmakingEnding
} = matchmakingSlice.actions
