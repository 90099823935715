import React from 'react'
import { Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material'
import { withStyles } from '@mui/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { Colors } from 'src/styling'

const CleanAccordion = withStyles({
	root: {
		'&:before': {
			display: 'none'
		}
	}
})(Accordion)

const CleanAccordionSummary = withStyles({
	root: {
		padding: 0
	},
	content: {
		margin: 0
	}
})(AccordionSummary)

interface Props {
	summary: React.ReactNode
	details?: React.ReactNode

	style?: {}
	summaryStyle?: {}
	detailsStyle?: {}
	iconButtonStyle?: {}
}

interface State {
	expanded: boolean
}
export default class CustomAccordion extends React.Component<Props, State> {
	state = {
		expanded: false
	}

	_renderIconButton() {
		const { details, iconButtonStyle } = this.props
		const { expanded } = this.state
		return (
			<IconButton disabled={!details} style={{ width: 40, height: 40, ...iconButtonStyle }}>
				{details &&
					(expanded ? (
						<ExpandLessIcon style={{ color: Colors.BRAND_HOT }} />
					) : (
						<ExpandMoreIcon style={{ color: Colors.BRAND_HOT }} />
					))}
			</IconButton>
		)
	}

	render() {
		const { summary, details, style, summaryStyle, detailsStyle } = this.props
		const { expanded } = this.state

		let cursorStyle: any = {}
		if (!details) {
			cursorStyle['cursor'] = 'default'
		}
		return (
			<CleanAccordion
				style={{
					borderRadius: 10,
					backgroundColor: Colors.PLUM,
					overflow: 'hidden',
					...style
				}}
				expanded={expanded}
				onChange={(event) => {
					if (details) {
						this.setState({ expanded: !expanded })
					}
				}}
			>
				<CleanAccordionSummary
					style={{
						...cursorStyle,
						...summaryStyle
					}}
				>
					<div
						style={{
							flex: 1,
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center'
						}}
					>
						{this._renderIconButton()}
						{summary}
					</div>
				</CleanAccordionSummary>
				{details && (
					<AccordionDetails
						style={{
							backgroundColor: Colors.BRAND_COOL,
							...detailsStyle
						}}
					>
						{details}
					</AccordionDetails>
				)}
			</CleanAccordion>
		)
	}
}
