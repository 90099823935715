/**
 * A singleton for managing Errors reporting.
 */
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { v4 as uuid } from 'uuid'

interface SentryUserData {
	id?: string
	email?: string
	username?: string
	ip_address?: string
}

class ErrorsManager {
	init() {
		if (process.env.REACT_APP_SENTRY_DSN) {
			Sentry.init({
				dsn: process.env.REACT_APP_SENTRY_DSN,
				integrations: [new BrowserTracing()],
				environment: process.env.REACT_APP_ENVIRONMENT,
				tracesSampleRate: 1.0,
				normalizeDepth: 20
			})
		}
	}

	/**
	 * Report error to Sentry
	 * @param error The error to be reported
	 * @param message An optional message to track
	 * @param dataObject An optional object to print out to the console
	 * @returns the generated error report id
	 */
	track(error: any, message?: string, dataObject?: any): String {
		// Print out the error to the console
		if (message) {
			console.error(message, error, dataObject)
		} else {
			console.error(error, dataObject)
		}
		// Send data to sentry only if the Sentry DSN is set for this environment
		if (process.env.REACT_APP_SENTRY_DSN) {
			return Sentry.captureException(error)
		}
		return uuid()
	}

	identifyUser(userData: SentryUserData | null) {
		if (process.env.REACT_APP_SENTRY_DSN) {
			return Sentry.setUser(userData)
		}
	}
}

const errorsManager = new ErrorsManager()

export default errorsManager
