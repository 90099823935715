export const Colors = {
	// twine color palette
	// monochrome
	DARK: '#363636',
	COAL: '#565656',
	SLATE: '#ECECEC',
	ICE: '#F9F9F9',
	// primary
	BRAND_HOT: '#4A2BC7',
	BRAND_DARK: '#2E168D',
	BRAND_COOL: '#6756AA',
	PLUM: '#BDC0D3',
	BREATHE: '#EBECF5',
	// secondary
	BLUE: '#2E71BA',
	SUN: '#EFC90D',
	GREEN: '#2EBA88',
	PUMICE: '#B1C4BB',
	MESH: '#D6E6DE',
	CORAL: '#DF685B',
	BACKGROUND: '#EBECF5',
	// TODO: Clarify color palette with Josh
	DARK_FONT: '#212b36',
	GREY_FONT: '#919eab',
	// TODO: Colors added by Josh in Host Welcome Invite Design
	DARK_GREY: {
		FONT: '#1b4068'
	},
	RED: {
		BACKGROUND: '#ffedeb',
		BORDER: '#ff9b90',
		FONT: '#572520',
		BUTTON_FONT: '#9b473d',
		BUTTON_BORDER: '#df685b',
		BUTTON_BACKGROUND: '#ebecf5'
	},
	LIGHT_BLUE: {
		BACKGROUND: '#f2f8ff',
		BORDER: '#9fbedf'
	},
	LIGHT_GREEN: {
		BACKGROUND: '#eefcf7',
		BORDER: '#56c59d',
		FONT: '#12503a'
	},
	SCROLLBAR_THUMB: '#637381'
}
