import React, { useEffect } from 'react'
import RenderRoutes from './routes/RenderRoutes'
import { QueryClient, QueryClientProvider } from 'react-query'
import AnalyticsManager from './managers/AnalyticsManager'
import { createApolloClient } from './feed/apolloClient'
import { ApolloProvider } from '@apollo/client'
import ApolloClientConnectionMonitor from './feed/ApolloClientConnectionMonitor'

const queryClient = new QueryClient()
const appolloClient = createApolloClient()

const App: React.FC = () => {
	// on mount
	useEffect(() => {
		AnalyticsManager.track('Open App')
		AnalyticsManager.time_event('Load App')
	}, [])

	return (
		<ApolloProvider client={appolloClient}>
			<ApolloClientConnectionMonitor />
			<QueryClientProvider client={queryClient}>
				<RenderRoutes />
			</QueryClientProvider>
		</ApolloProvider>
	)
}

export default App
