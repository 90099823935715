import React from 'react'
import { FormControl, Select, MenuItem } from '@mui/material'
import styled from 'styled-components'

interface Props {
	options: any[]
	optionRenderer?: (value: any) => React.ReactNode
	value?: any
	defaultValue?: any
	disabled?: boolean
	valueRenderer?: (value: any) => React.ReactNode
	onChange: (value: any) => void
	// converters for going from value to label strings
	getLabelTitle?: (value: any) => string
	style?: {}
	inputStyle?: {}
	placeholder?: string
	onOpen?: () => void
	onClose?: () => void
}

const SingleSelect: React.FC<Props> = ({
	options,
	optionRenderer,
	value,
	disabled,
	valueRenderer,
	onChange,
	getLabelTitle,
	style,
	inputStyle,
	placeholder,
	onOpen,
	onClose
}) => (
	<FormControl
		style={{
			background: 'white',
			borderRadius: 4,
			overflow: 'hidden',
			width: '100%',
			...style
		}}
	>
		<Select
			labelId='demo-controlled-open-select-label'
			id='demo-controlled-open-select'
			sx={{
				'& .MuiSelect-select': { padding: '9px 14px' },
				...{ border: '1px solid black', borderRadius: 2 }
			}}
			placeholder={placeholder}
			style={{ border: 'none' }}
			defaultValue='none'
			onClose={onClose}
			onOpen={onOpen}
			value={value}
			disabled={disabled}
			onChange={(event) => onChange(event.target.value)}
			renderValue={(value) => {
				if (valueRenderer) return valueRenderer(value)
				let title: any = getLabelTitle ? getLabelTitle(value) : value
				return (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							...(value === 'none' ? { color: '#919eab' } : {}),
							...inputStyle
						}}
					>
						<InputTitle>{title}</InputTitle>
					</div>
				)
			}}
		>
			{placeholder && (
				<MenuItem key='menu-item-placeholder' value='none'>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							color: '#919eab',
							...inputStyle
						}}
					>
						<InputTitle>{placeholder}</InputTitle>
					</div>
				</MenuItem>
			)}
			{options.map((option: any) => {
				if (optionRenderer)
					return (
						<MenuItem sx={{ color: 'green' }} key={option} value={option}>
							{optionRenderer(option)}
						</MenuItem>
					)

				let title: any = getLabelTitle ? getLabelTitle(option) : option

				return (
					<MenuItem key={option} value={option}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								...inputStyle
							}}
						>
							<InputTitle>{title}</InputTitle>
						</div>
					</MenuItem>
				)
			})}
		</Select>
	</FormControl>
)
const InputTitle = styled.div`
	font-size: 14px;
	font-weight: 400;
`

export default SingleSelect
