import * as Sentry from '@sentry/react'
import { AnyAction } from '@reduxjs/toolkit'
import { RootState } from 'src/store/rootStore'
import { ParticipantDictionary } from 'src/models/participant'

const removeSensitiveInformationFromParticipantDictionary = (dict: ParticipantDictionary) => {
	return Object.keys(dict).reduce((newParticipants, userId) => {
		newParticipants[userId] = {
			...dict[userId],
			name: null, // remove participant name
			picUrl: null // remove participant's avatar url
		}
		return newParticipants
	}, {})
}

// transform the state to remove user content and unnecessary PII
const removeSensitiveUserInformationFromState = (state: RootState) => ({
	...state,
	user: {
		...state.user,
		user: {
			...state.user.user,
			name: null, // remove name
			picUrl: null // remove user's avatar url
		}
	},
	participants: removeSensitiveInformationFromParticipantDictionary(state.participants),
	zoom: {
		...state.zoom,
		zoomUserContext: {
			...state.zoom.zoomUserContext,
			screenName: null // remove user's Zoom screen name
		},
		supportedJsApis: null // irrelevant to debugging, avoid cluttering Sentry issues
	}
})

const removeSensitiveUserInformationFromActions = (action: AnyAction) => {
	if (action.type === 'zoom/setZoomUserContext' || action.type === 'zoom/updateZoomUserContext') {
		// return a transformed action to remove user's screen name
		return { ...action, payload: { ...action.payload, screenName: null } }
	}
	if (action.type === 'participants/setParticipants') {
		// payload is a participant dictionary
		const participantDictionary = action.payload

		return {
			...action,
			payload: removeSensitiveInformationFromParticipantDictionary(participantDictionary)
		}
	}
	if (
		action.type === 'participants/addParticipant' ||
		action.type === 'participants/updateParticipantTags' ||
		action.type === 'participants/updateParticipantByUUID'
	) {
		// payload is a participant object
		return {
			...action,
			payload: {
				...action.payload,
				name: null, // omit participant name
				picUrl: null // omit participant's avatar url
			}
		}
	}
	if (action.type === 'user/setUser') {
		// payload is a user object
		return {
			...action,
			payload: {
				...action.payload,
				// omit the following fields
				name: null,
				email: null,
				picUrl: null
			}
		}
	}
	if (action.type === 'user/setUserName' || action.type === 'user/setUserPicUrl') {
		// payload is a string representing the user's name or image URL
		return {
			...action,
			payload: null
		}
	}

	return action
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
	actionTransformer: removeSensitiveUserInformationFromActions,
	stateTransformer: removeSensitiveUserInformationFromState
})

export default sentryReduxEnhancer
