import { MeetingInfo, MeetingState } from 'src/models/meeting'
import {
	Participant,
	ParticipantDictionary,
	OnboardingTypeEnum,
	ParticipantLocation,
	ParticipantOnboardingShown
} from 'src/models/participant'
import { Task } from 'src/models/task'
import { ActivityInfo } from 'src/models/activity'
import { MatchActivityType } from 'src/models/matchActivity'

export interface onParticipantBreakoutChangeResponse {
	action: 'leave' | 'join'
	meetingId: string
	userId: string
	name: string
	tableNumber: number
}

export interface OnMeetingUpdatedResponse {
	meetingState: {
		meeting: MeetingState
		meetingInfo: MeetingInfo
		participants: ParticipantDictionary
	}
}

export interface OnMoveRequestedResponse {
	meetingId: string
	userId: string
	participantUUID: string
	destination: ParticipantLocation
}

export interface OnParticipantJoinedResponse {
	participant: Participant
}

export interface OnParticipantLeftResponse {
	userId: string
}

export interface OnJoinedResponse {
	userId: string
	onboardingShown: ParticipantOnboardingShown
}

export interface onParticipantJoinedResponse {
	participant: Participant
}

export interface onParticipantLeftResponse {
	userId: string
}

export interface onJoinMeetingResponse {
	meeting: MeetingState
	meetingInfo: MeetingInfo
	participants: ParticipantDictionary
}

export type onSetActivityResponse = ActivityInfo

export interface onActivityUpdatedResponse {
	tableId: string
	tasks: Task[]
}

export interface onMoveExecutedResponse {
	meetingId: string
	userId: string
	participantUUID: string
	destination: ParticipantLocation
}

export interface onMatchActivityUpdatedResponse {
	activityType: MatchActivityType
	question?: string
}

export interface MatchFoundMatch {
	breakoutRoomUUID?: string
	matchId: string
	matchUserIds?: string[]
	matchParticipantUUIDs?: string[]
	channelId?: string
}

export interface OnMatchesFoundResponse {
	meetingId?: string
	timeRemaining?: number
	matches: MatchFoundMatch[]
	participantMatched?: boolean
	matchRound?: number
}

export interface onMatchFoundResponse {
	matchId: string
	timeRemaining: number
	matchUserIds: string[]
	matchParticipantUUIDs: string[]
	tableId: string
}

export interface onMatchRoundStartedResponse {
	endTime: number
}

export interface SubmitUserFeedbackData {
	content: string
	metadata?: {
		runningContext: string
		clientVersion: string
		browserVersion: string
		userAgent: string
	}
}

export interface OnOnboardingShownResponse {
	userId: string
	type: OnboardingTypeEnum
}

export interface OnParticipantReadyToMatchResponse {
	meetingId: string
	userId: string
	isHost: boolean
	tags: string[]
	wants: string[]
}

type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>

export interface UpdateParticipantByUUIDParams {
	participant: AtLeast<Participant, 'participantUUID'>
}

/**
 * Status for the Matchmaking assignment process
 *
 * IDLE (idle)
 * PENDING (in-progress)
 * DONE (process completed)
 */
export enum AssignmentStatus {
	IDLE = 'idle',
	PENDING = 'pending',
	DONE = 'done'
}
