import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { rootStore } from './store/rootStore'
import { Provider } from 'react-redux'
import { RecoilRoot } from 'recoil'
import { ThemeProvider } from '@mui/material/styles'
import { muiTheme } from 'src/styling'
import ErrorsManager from 'src/managers/ErrorsManager'

import './fonts/Metropolis.css'
ErrorsManager.init()

ReactDOM.render(
	<Provider store={rootStore}>
		<StrictMode>
			<RecoilRoot>
				<ThemeProvider theme={muiTheme}>
					<App />
				</ThemeProvider>
			</RecoilRoot>
		</StrictMode>
	</Provider>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(log.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
