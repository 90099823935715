import toast from 'react-hot-toast'
import { Button, ThemeProvider, Typography } from '@mui/material'

import { Colors, muiTheme } from 'src/styling'
import { Linkify } from 'src/components/general'

// applied to all toasts via <Toaster /> instantiation
export const toastStyles = {
	borderRadius: 8,
	boxShadow: '0 8px 16px 0 rgba(145, 158, 171, 0.24)',
	backgroundColor: '#fff',
	minWidth: 'max-content'
}

class AlertManager {
	/**
	 * Keeps track of Toast id of a new message notification to replace the content when more messages come in
	 */
	newMessageNotificationToastId: string | undefined

	message(
		text: string,
		type: 'info' | 'success' | 'error' | 'warning' | 'loading',
		duration: number = 4000,
		toastId?: string
	): string {
		const toastOptions = {
			duration,
			id: toastId
		}

		switch (type) {
			case 'info':
				return toast(text, {
					iconTheme: {
						primary: `${Colors.GREEN}`,
						secondary: '#fff'
					},
					...toastOptions
				})
			case 'success':
				return toast.success(text, {
					iconTheme: {
						primary: `${Colors.GREEN}`,
						secondary: '#fff'
					},
					...toastOptions
				})
			case 'error':
				return toast.error(text, {
					iconTheme: {
						primary: `${Colors.CORAL}`,
						secondary: '#fff'
					},
					...toastOptions
				})
			case 'warning':
				return toast.error(text, toastOptions)
			case 'loading':
				return toast.loading(text, toastOptions)
			default:
				return ''
		}
	}

	dismiss(toastId: string) {
		toast.dismiss(toastId)
	}

	announce(title: string, message?: string) {
		toast(
			(t) => {
				return (
					<span>
						<ThemeProvider theme={muiTheme}>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-end',
									textAlign: 'right'
								}}
							>
								<Typography variant='overline'>{title}</Typography>
								{message && (
									<Linkify>
										<Typography style={{ marginTop: 10 }}>{message}</Typography>
									</Linkify>
								)}
								<Button onClick={() => toast.dismiss(t.id)} style={{ marginTop: 10 }} size='small'>
									Dismiss
								</Button>
							</div>
						</ThemeProvider>
					</span>
				)
			},
			{ duration: 1000000, position: 'top-right' }
		)
	}
}

export default new AlertManager()
