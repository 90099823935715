import { ParticipantOnboardingShown } from './../../models/participant'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OnboardingTypeEnum } from 'src/models/participant'
import { User, UserBreakoutSettings } from 'src/models/user/user'
import * as ls from 'local-storage'
import { PersonalizedFeedSettings } from 'src/feed/graphql/types'
import { LOGOUT_ACTION } from '../actions'

export interface UserState {
	authenticated: boolean // is the user authenticated with our twine-zapp-api?
	user: User
	jwtToken: string | null
	feedToken: string | null
	refreshToken: string | null
	onboardingShown: ParticipantOnboardingShown
	personalizedAppSettings: PersonalizedFeedSettings
}

export const initialState: UserState = {
	authenticated: false, // is the user authenticated with our twine-zapp-api?
	jwtToken: ls.get<string | null>('JWT'),
	feedToken: ls.get<string | null>('JWT_AMBIENT'),
	refreshToken: ls.get<string | null>('REFRESH_TOKEN'),
	user: {
		userId: '',
		twineUserId: '',
		twineOrgId: '',
		name: '',
		email: '',
		hasAmbient: false,
		type: null,
		recordingSettings: {
			zoomLocalRecordingEnabled: true
		},
		breakoutSettings: {
			zoomBreakoutRoomsEnabled: true,
			zoomMeetingCapacity: 100,
			zoomLargeMeetingEnabled: false,
			zoomLargeMeetingCapacity: 500
		},
		subscriptions: [],
		selectedUseCase: null,
		zoomOrgId: undefined
	},
	onboardingShown: {
		[OnboardingTypeEnum.quickStart]: true, // set all to true by default
		[OnboardingTypeEnum.shuffle]: true,
		[OnboardingTypeEnum.mapFlow]: true,
		[OnboardingTypeEnum.tagsFlow]: true,
		[OnboardingTypeEnum.abFlow]: true,
		[OnboardingTypeEnum.tryCreatingAMap]: true,
		[OnboardingTypeEnum.minimalInMeeting]: true
	},
	personalizedAppSettings: {}
}

interface OnboardingShownAction {
	onboardingType: string
	value: boolean
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserEmail: (state: UserState, action: PayloadAction<User['email']>) => {
			return { ...state, user: { ...state.user, email: action.payload } }
		},
		setUserName: (state: UserState, action: PayloadAction<User['name']>) => {
			return { ...state, user: { ...state.user, name: action.payload } }
		},
		setTwineUserId: (state: UserState, action: PayloadAction<User['twineUserId']>) => {
			return {
				...state,
				user: { ...state.user, twineUserId: action.payload }
			}
		},
		setTwineOrgId: (state: UserState, action: PayloadAction<string>) => {
			return {
				...state,
				user: { ...state.user, twineOrgId: action.payload }
			}
		},
		setUserId: (state: UserState, action: PayloadAction<User['userId']>) => {
			return {
				...state,
				user: { ...state.user, userId: action.payload }
			}
		},
		setUserType: (state: UserState, action: PayloadAction<User['type']>) => {
			return { ...state, user: { ...state.user, type: action.payload } }
		},
		setUserPicUrl: (state: UserState, action: PayloadAction<User['picUrl']>) => {
			return {
				...state,
				user: { ...state.user, picUrl: action.payload }
			}
		},
		setUser: (state: UserState, action: PayloadAction<User>) => {
			return { ...state, user: action.payload }
		},
		setUserBreakoutSettings: (state: UserState, action: PayloadAction<UserBreakoutSettings>) => {
			return {
				...state,
				user: {
					...state.user,
					breakoutSettings: action.payload
				}
			}
		},
		setUserAppSettings: (state: UserState, action: PayloadAction<PersonalizedFeedSettings>) => {
			return {
				...state,
				personalizedAppSettings: action.payload
			}
		},
		setAuthenticated: (state: UserState, action: PayloadAction<boolean>) => {
			return { ...state, authenticated: action.payload }
		},
		setOnboardingShown: (state: UserState, { payload }: PayloadAction<ParticipantOnboardingShown>) => {
			return {
				...state,
				onboardingShown: payload
			}
		},
		setOnboardingShownField: (
			state: UserState,
			{ payload: { onboardingType, value } }: PayloadAction<OnboardingShownAction>
		) => {
			return {
				...state,
				onboardingShown: {
					...state.onboardingShown,
					[onboardingType]: value
				}
			}
		},
		setJwtToken: (state: UserState, action: PayloadAction<string>) => {
			ls.set('JWT', action.payload)
			return {
				...state,
				jwtToken: action.payload
			}
		},
		setAuthTokens: (state: UserState, action: PayloadAction<{ token: string | null; refresh: string | null }>) => {
			ls.set('JWT_AMBIENT', action.payload.token)
			ls.set('REFRESH_TOKEN', action.payload.refresh)
			return {
				...state,
				feedToken: action.payload.token,
				refreshToken: action.payload.refresh
			}
		},
		clearAuthTokens: (state: UserState) => {
			ls.remove('JWT_AMBIENT')
			ls.remove('REFRESH_TOKEN')
			return {
				...state,
				feedToken: null,
				refreshToken: null
			}
		}
	}
})

export default userSlice

export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		// clear out persisted state on logout and return the initial state
		case LOGOUT_ACTION.type:
			ls.remove('JWT')
			ls.remove('JWT_AMBIENT')
			ls.remove('REFRESH_TOKEN')
			// return the initial state when logging out
			return initialState;

		default:
			return userSlice.reducer(state, action);
	}
}

export const {
	setUserEmail,
	setUser,
	setUserBreakoutSettings,
	setUserAppSettings,
	setUserName,
	setUserId,
	setTwineUserId,
	setTwineOrgId,
	setUserType,
	setUserPicUrl,
	setAuthenticated,
	setOnboardingShown,
	setOnboardingShownField,
	setJwtToken,
	setAuthTokens,
	clearAuthTokens,
} = userSlice.actions
