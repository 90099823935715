import useBrowser from 'src/hooks/useBrowser'
import { lazy, Suspense } from 'react'

// by using lazy imports, we can split up the bundle size to make loading much faster
const ZoomAppRoutes = lazy(async () => await import('./LazyLoadedZoomAppRouter'))
const BrowserAppRoutes = lazy(async () => await import('src/feed/routes/LazyLoadedBrowserAppRouter'))

const RenderRoutes = () => {
	const inBrowser = useBrowser()
	return (
		<Suspense fallback={<></>}>
			{!inBrowser && <ZoomAppRoutes />}
			{inBrowser && <BrowserAppRoutes />}
		</Suspense>
	)
}

export default RenderRoutes
