import React from 'react'
import { Dialog as MuiDialog, DialogContent, DialogProps as MuiDialogProps, IconButton } from '@mui/material'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'

type DialogProps = MuiDialogProps & {
	hideCloseButton?: boolean
}

export const Dialog: React.FunctionComponent<DialogProps> = (props) => {
	const { onClose, children, hideCloseButton } = props

	return (
		<MuiDialog {...props}>
			{onClose && !hideCloseButton && (
				<IconButton
					aria-label='close'
					onClick={() => {
						onClose({}, 'backdropClick')
					}}
					style={{
						position: 'absolute',
						right: 8,
						top: 8
					}}
				>
					<CloseRoundedIcon />
				</IconButton>
			)}
			<DialogContent
				style={{
					margin: '28px 32px',
					padding: 0,
					display: 'flex',
					flexDirection: 'column'
				}}
			>
				{children}
			</DialogContent>
		</MuiDialog>
	)
}
