import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type MapState = {
	mapStarting: boolean // loading state for map
}

export const initialState: MapState = {
	mapStarting: false
}

export const mapSlice = createSlice({
	name: 'map',
	initialState,
	reducers: {
		setMapStarting: (state: MapState, action: PayloadAction<boolean>) => {
			return {
				...state,
				mapStarting: action.payload
			}
		}
	}
})

export default mapSlice

export const mapReducer = mapSlice.reducer
export const { setMapStarting } = mapSlice.actions
