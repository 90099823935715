import { Colors } from 'src/styling'
import styled from 'styled-components'
import { TextField } from '@mui/material'

export const Input = styled(TextField)`
	fieldset {
		border-radius: 8px;
		border: 1px solid #c4cdd5;
	}
	input {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #212b36;
		padding: 0px 14px;
		height: 40px;
		background: white;
		border-radius: 8px;

		&::placeholder {
			font-weight: normal;
			color: #c4cdd5;
		}
	}
	&.MuiFormControl-root {
		width: 100%;
	}
	& .MuiOutlinedInput-root {
		width: 100%;
	}
	& .MuiOutlinedInput-root:hover {
		& > fieldset {
			border: 1px solid #0000005b;
		}
	}
	& .MuiOutlinedInput-root {
		&.Mui-focused fieldset {
			border: 1px solid ${Colors.BRAND_HOT};
		}
	}
	& .MuiOutlinedInput-root {
		&.Mui-disabled input {
			opacity: 1;
			color: rgba(0, 0, 0, 0.38);
			background: rgb(244, 244, 244);
		}
	}
`
