import { ParticipantLocation } from './../../models/participant'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Participant, ParticipantDictionary, ParticipantId } from 'src/models/participant'
import { OnParticipantReadyToMatchResponse } from './../../managers/SocketManager/types'

/** State */
export type ParticipantState = ParticipantDictionary

interface UpdateLocationPayload {
	userId: Participant['userId']
	location: ParticipantLocation
}

export const initialState: ParticipantState = {}

export const participantSlice = createSlice({
	name: 'participants',
	initialState,
	reducers: {
		setParticipants: (state: ParticipantState, action: PayloadAction<ParticipantDictionary>) => {
			return action.payload
		},
		addParticipant: (state: ParticipantState, action: PayloadAction<Participant>) => {
			const participant = action.payload
			return {
				...state,
				[participant.userId]: participant
			}
		},
		removeParticipant: (state: ParticipantState, action: PayloadAction<ParticipantId>) => {
			const participantIdToRemove = action.payload
			return Object.keys(state)
				.filter((participantId) => participantId !== participantIdToRemove)
				.reduce((obj, key) => {
					obj[key] = state[key]
					return obj
				}, {})
		},
		updateParticipantTags: (
			state: ParticipantState,
			{ payload: participant }: PayloadAction<OnParticipantReadyToMatchResponse>
		) => {
			const newParticipant = {
				...state[participant.userId]
			}
			newParticipant.tags = JSON.stringify(participant.tags)
			newParticipant.wants = JSON.stringify(participant.wants)
			const newParticipantState = { ...state }
			newParticipantState[newParticipant.userId] = newParticipant

			return newParticipantState
		},
		clearAllParticipantTags: (state: ParticipantState) => {
			const participantObjects = Object.values(state)
			const newState = {}
			participantObjects.forEach((p: Participant) => {
				newState[p.userId] = {
					...p,
					tags: '[]',
					wants: '[]'
				}
			})

			return newState
		},
		updateParticipantPendingLocation: (
			state: ParticipantState,
			{ payload: { userId, location } }: PayloadAction<UpdateLocationPayload>
		) => {
			return {
				...state,
				[userId]: { ...state[userId], pendingLocation: location }
			}
		},
		updateParticipantCurrentLocation: (
			state: ParticipantState,
			{ payload: { userId, location } }: PayloadAction<UpdateLocationPayload>
		) => {
			const newParticipant = { ...state[userId] }

			// remove their pending location
			delete newParticipant.pendingLocation
			// update their current location
			newParticipant.currentLocation = location

			return {
				...state,
				[userId]: newParticipant
			}
		},
		updateParticipantByUUID: (state: ParticipantState, action: PayloadAction<Participant>) => {
			const updatedParticipant = action.payload
			const newState = {
				...state,
				[updatedParticipant.userId]: updatedParticipant
			}

			// If a user changes participant type (i.e. ghost -> app or vice versa), we don't want a copy of them
			const participants = Object.values(state)
			const duplicateParticipant = participants.find(
				(p: Participant) =>
					p.participantUUID === updatedParticipant.participantUUID && p.userId !== updatedParticipant.userId
			)
			if (duplicateParticipant) {
				delete newState[`${duplicateParticipant.userId}`]
			}

			return newState
		}
	}
})

export default participantSlice

export const participantsReducer = participantSlice.reducer
export const {
	setParticipants,
	addParticipant,
	removeParticipant,
	updateParticipantPendingLocation,
	updateParticipantCurrentLocation,
	clearAllParticipantTags,
	updateParticipantTags,
	updateParticipantByUUID
} = participantSlice.actions
