import { createTheme } from '@mui/material'
import { Colors } from './Colors'

export const muiTheme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 500,
			md: 650,
			lg: 1200,
			xl: 1536
		}
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 20
				}
			}
		},
		MuiCard: {
			styleOverrides: {
				root: {
					borderRadius: 10
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					fontSize: '1em',
					borderRadius: 10
				}
			}
		}
	},
	palette: {
		primary: {
			main: Colors.BRAND_HOT,
			dark: Colors.BRAND_DARK
		},
		secondary: {
			main: Colors.BRAND_COOL
		},
		success: {
			main: '#2cd9c5'
		},
		error: {
			main: '#e75c47'
		}
	},
	typography: {
		fontFamily: ['Metropolis', 'Helvetica', ' sans-serif'].join(','),
		button: {
			textTransform: 'none'
		}
	}
})
