export type ParticipantId = string

export interface ParticipantDictionary {
	[userId: string]: Participant
}

export enum ParticipantLocationTypeEnum {
	/** main meeting */
	main = 'main',
	/** a seat at a table */
	seat = 'seat',
	/** generically at a table without a seat */
	table = 'table'
}

export type ParticipantLocation = {
	type: ParticipantLocationTypeEnum
	tableId?: string
	seatId?: string
}

export enum ParticipantType {
	App = 'app',
	Ghost = 'ghost',
	Guest = 'guest',
	Other = 'other'
}

export enum OnboardingTypeEnum {
	quickStart = 'quickStart',
	shuffle = 'shuffle',
	mapFlow = 'mapFlow',
	tagsFlow = 'tagsFlow',
	abFlow = 'abFlow',
	tryCreatingAMap = 'tryCreatingAMap',
	minimalInMeeting = 'minimalInMeeting'
}

export enum ParticipantStatus {
	Seated = 'seated',
	Pending = 'pending',
	Gone = 'gone'
}

export enum ParticipantMatchingStatus {
	Matching = 'matching',
	Matched = 'matched',
	Excluded = 'excluded'
}

export interface ParticipantOnboardingShown {
	[OnboardingTypeEnum.quickStart]: boolean
	[OnboardingTypeEnum.shuffle]: boolean
	[OnboardingTypeEnum.mapFlow]: boolean
	[OnboardingTypeEnum.tagsFlow]: boolean
	[OnboardingTypeEnum.abFlow]: boolean
	[OnboardingTypeEnum.tryCreatingAMap]: boolean
	[OnboardingTypeEnum.minimalInMeeting]: boolean
}

export interface Participant {
	id?: number
	userId: ParticipantId
	participantId: ParticipantId
	participantUUID: ParticipantId
	name: string
	picUrl?: string
	labels?: string[]
	status?: ParticipantStatus
	currentLocation?: ParticipantLocation
	pendingLocation?: ParticipantLocation
	tags?: string // format: "[]"
	wants?: string // format: "[]"
	matching?: ParticipantMatchingStatus
	participantType?: ParticipantType
	onboardingShown?: ParticipantOnboardingShown
}
