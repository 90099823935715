import errorsManager from 'src/managers/ErrorsManager'
import { MainOccupantsState } from '../../models/meeting'
import { Table } from '../../models/table'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MeetingState } from 'src/models/meeting'
import {
	MainMeetingSeat,
	Task,
	TaskList,
	SeatStatus,
	Seat,
	ParticipantLocation,
	ParticipantLocationTypeEnum
} from 'src/models'
import _ from 'lodash'

export const initialState: MeetingState = {
	mainOccupants: [],
	tables: []
}

type UserId = string

interface UpdateTaskAction {
	tableId: string
	tasks: Task[]
}

interface MoveParticipantToLocationPayload {
	userId: UserId
	participantUUID: string
	location: ParticipantLocation
	status: SeatStatus
}

export const meetingSlice = createSlice({
	name: 'meeting',
	initialState,
	reducers: {
		resetMeeting: (state: MeetingState) => {
			return initialState
		},
		setMeeting: (state: MeetingState, action: PayloadAction<MeetingState>) => {
			return action.payload
		},
		resetTables: (state: MeetingState) => {
			return {
				...state,
				tables: []
			}
		},
		setTables: (state: MeetingState, action: PayloadAction<Table[]>) => {
			return {
				...state,
				tables: action.payload
			}
		},
		setMainOccupants: (state: MeetingState, action: PayloadAction<MainOccupantsState>) => {
			return {
				...state,
				mainOccupants: action.payload
			}
		},
		updateTaskLists: (state: MeetingState, { payload: taskList }: PayloadAction<TaskList>) => {
			return {
				...state,
				tables: state.tables.map((t: Table) => ({ ...t, taskList }))
			}
		},
		updateTableTasks: (state: MeetingState, { payload: { tableId, tasks } }: PayloadAction<UpdateTaskAction>) => {
			const updatedTables = [...state.tables]
			const desiredTable = updatedTables.find((t: Table) => t.tableId === tableId)

			if (desiredTable) {
				// if the table has a task list, update it
				if (desiredTable.taskList) {
					return {
						...state,
						tables: [
							...state.tables.filter((t: Table) => t.tableId !== tableId),
							{
								...desiredTable,
								taskList: {
									...desiredTable?.taskList,
									tasks
								}
							}
						]
					}
				} else {
					// if the table does not have a task list, add a new one
					const newTaskList: TaskList = {
						tasks
					}

					return {
						...state,
						tables: [
							...state.tables.filter((t: Table) => t.tableId !== tableId),
							{
								...desiredTable,
								taskList: newTaskList
							}
						]
					}
				}
			}

			// if the table doesn't exist, do nothing
			return state
		},
		removeParticipantFromMeeting: (state: MeetingState, action: PayloadAction<UserId>) => {
			const userId = action.payload

			// update seat status if they're seated on a table
			const tables = _.cloneDeep(state.tables)
			tables.forEach((table: Table) =>
				table.seats.forEach((seat: Seat) => {
					if (seat.occupantId === userId) {
						seat.status = 'empty' as SeatStatus
						delete seat.occupantId
					}
				})
			)

			// filter main occupants
			const filteredMainOccupants = state.mainOccupants.filter(
				(seat: MainMeetingSeat) => seat.occupantId !== userId
			)

			// return a new object representing the updated state
			return {
				mainOccupants: filteredMainOccupants,
				tables
			}
		},
		moveParticipantToLocation: (
			state: MeetingState,
			{ payload: { userId, participantUUID, location, status } }: PayloadAction<MoveParticipantToLocationPayload>
		) => {
			const { seatId, type } = location

			// if moving to the main
			if (type === ParticipantLocationTypeEnum.main) {
				// update seat status to empty if they're seated on a table
				const tables = _.cloneDeep(state.tables)
				tables.forEach((table: Table) =>
					table.seats.forEach((seat: Seat) => {
						if (seat.occupantId === userId || seat.occupantUUID === participantUUID) {
							seat.status = 'empty' as SeatStatus
							delete seat.occupantId
							delete seat.occupantUUID
						}
					})
				)

				let mainOccupants = [...state.mainOccupants]
				const mainMeetingSeatsWithUserId = mainOccupants.filter(
					(seat: MainMeetingSeat) => seat.occupantId === userId
				)
				const mainMeetingSeatsWithParticipantUUID = mainOccupants.filter(
					(seat: MainMeetingSeat) => seat.occupantUUID === participantUUID
				)

				if (mainMeetingSeatsWithUserId.length !== 0) {
					// remove the users with this userID
					const filteredSeats = mainOccupants.filter((seat: MainMeetingSeat) => seat.occupantId !== userId)

					mainOccupants = [...filteredSeats]
				} else if (mainMeetingSeatsWithParticipantUUID.length !== 0) {
					// remove the users with this participantUUID
					const filteredSeats = mainOccupants.filter(
						(seat: MainMeetingSeat) => seat.occupantUUID !== participantUUID
					)

					mainOccupants = [...filteredSeats]
				}

				// add a new seat in the main for this user
				const newSeat: MainMeetingSeat = {
					occupantId: userId,
					occupantUUID: participantUUID,
					status: status
				}
				mainOccupants = [...mainOccupants, newSeat]

				return {
					tables,
					mainOccupants
				}
			} else if (type === ParticipantLocationTypeEnum.seat) {
				// filter main occupants to exclude this participant
				const mainOccupants = state.mainOccupants.filter(
					(seat: MainMeetingSeat) => seat.occupantId !== userId && seat.occupantUUID !== participantUUID
				)

				// find the seat, and add them there with specified status
				const tables = _.cloneDeep(state.tables)
				// modify the seat directly
				tables.forEach((table: Table) => {
					table.seats.forEach((seat: Seat) => {
						// if this is the specified seat, move the user there
						if (seat.seatId === seatId) {
							seat.status = status
							seat.occupantId = userId
							seat.occupantUUID = participantUUID
						} else if (
							// otherwise, if this seat is occupied by someone of the same userId OR participant UUID, remove them.
							(seat.occupantId && seat.occupantId === userId) ||
							(seat.occupantUUID && seat.occupantUUID === participantUUID)
						) {
							// if they happen to be "occupying" a different seat, empty it
							seat.status = 'empty'
							delete seat.occupantId
							delete seat.occupantUUID
						}
					})
				})

				return {
					mainOccupants,
					tables
				}
			} else if (type === ParticipantLocationTypeEnum.table) {
				errorsManager.track(new Error('Error occurred in meeting slice, participant location has type "table"'))
			}

			return state
		}
	}
})

export default meetingSlice

export const meetingReducer = meetingSlice.reducer
export const {
	resetMeeting,
	setMeeting,
	setTables,
	resetTables,
	setMainOccupants,
	removeParticipantFromMeeting,
	moveParticipantToLocation,
	updateTaskLists,
	updateTableTasks
} = meetingSlice.actions
