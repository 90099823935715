import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Stripe from 'stripe'

type StripeSession = Stripe.Response<Stripe.Checkout.Session>

export type SessionState = {
	stripeSession: StripeSession | null
}

export const initialState: SessionState = {
	stripeSession: null
}

export const sessionSlice = createSlice({
	name: 'session',
	initialState,
	reducers: {
		setStripeSession: (state: SessionState, action: PayloadAction<any>) => {
			return {
				...state,
				stripeSession: action.payload
			}
		}
	}
})

export default sessionSlice

export const sessionReducer = sessionSlice.reducer
export const { setStripeSession } = sessionSlice.actions
