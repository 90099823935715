import { createSlice, PayloadAction } from '@reduxjs/toolkit'

/**
 * These phases correspond to the various states a participant can exist in
 * during matchmaking.
 */
export enum UserMatchmakingTransitionPhase {
	// A default state when matchmaking is not occurring
	None,
	// The participant is in the process of being moved to/from a breakout room
	Moving,
	// A participant finished transitioning and a breakout session is ongoing
	Arrived
}

export type UserMatchmakingState = {
	/**
	 * A participant will flow through various phases when
	 * moving between breakout rooms.
	 */
	transitionPhase: UserMatchmakingTransitionPhase
}

export const initialState: UserMatchmakingState = {
	transitionPhase: UserMatchmakingTransitionPhase.None
}

export const userMatchmakingSlice = createSlice({
	name: 'userMatchmaking',
	initialState,
	reducers: {
		setTransitionPhase: (state: UserMatchmakingState, action: PayloadAction<UserMatchmakingTransitionPhase>) => {
			return {
				...state,
				transitionPhase: action.payload
			}
		}
	}
})

export default userMatchmakingSlice

export const userMatchmakingReducer = userMatchmakingSlice.reducer
export const { setTransitionPhase } = userMatchmakingSlice.actions
