import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import AlertManager from 'src/managers/AlertManager/AlertManager'
import { SocketErrorObject, SocketIODisconnectReasons } from 'src/services/Socket'

// these are derived from Socket IO's v2 documentation
// see: https://socket.io/docs/v2/client-api/
export enum SocketStatus {
	connected = 'connected',
	disconnected = 'disconnected',
	error = 'error',
	connecting_error = 'connecting error',
	connection_timeout = 'connection timeout',
	reconnected = 'reconnected',
	reconnecting = 'reconnecting',
	error_reconnecting = 'error reconnecting',
	reconnect_failed = 'reconnect failed'
}

export interface SocketState {
	status: SocketStatus | null
	details?: string
	reconnectAttemptNumber?: number
}

export const initialState: SocketState = { status: null }

let toastId: string

const handleLoadingNotification = (message: string) => {
	// update the existing toast if it exists
	if (toastId) {
		toastId = AlertManager.message(message, 'loading', 3000, toastId)
	} else {
		toastId = AlertManager.message(message, 'loading', 3000)
	}
}

const handleSuccessNotification = (message: string) => {
	if (toastId) {
		toastId = AlertManager.message(message, 'success', 3000, toastId)
	} else {
		toastId = AlertManager.message(message, 'success', 3000)
	}
}

const handleErrorNotification = (message: string) => {
	if (toastId) {
		toastId = AlertManager.message(message, 'error', 3000, toastId)
	} else {
		toastId = AlertManager.message(message, 'error', 3000)
	}
}

export const socketSlice = createSlice({
	name: 'socket',
	initialState,
	reducers: {
		socketConnected: (state: SocketState) => {
			if (state.status !== null) handleSuccessNotification('Connected!')
			return {
				...state,
				status: SocketStatus.connected
			}
		},
		socketDisconnected: (state: SocketState, action: PayloadAction<SocketIODisconnectReasons>) => {
			handleLoadingNotification('Connecting...')
			return {
				...state,
				status: SocketStatus.disconnected,
				details: JSON.stringify(action.payload)
			}
		},
		socketConnectionTimeout: (state: SocketState) => {
			handleErrorNotification('Socket connection timed out.')
			return {
				...state,
				status: SocketStatus.connection_timeout
			}
		},
		socketReconnected: (state: SocketState, action: PayloadAction<number>) => {
			handleSuccessNotification('Reconnected!')
			return {
				...state,
				status: SocketStatus.reconnected,
				reconnectAttemptNumber: action.payload
			}
		},
		socketReconnecting: (state: SocketState, action: PayloadAction<number>) => {
			handleLoadingNotification('Reconnecting...')
			return {
				...state,
				status: SocketStatus.reconnecting,
				reconnectAttemptNumber: action.payload
			}
		},
		socketReconnectFailed: (state: SocketState) => {
			handleErrorNotification('Reconnect failed.')
			return {
				...state,
				status: SocketStatus.reconnect_failed
			}
		},
		socketError: (state: SocketState, action: PayloadAction<SocketErrorObject>) => {
			handleErrorNotification('Socket error occurred.')
			return {
				...state,
				status: SocketStatus.error,
				...(action.payload.payload ? { details: JSON.stringify(action.payload.payload) } : {})
			}
		},
		socketConnectingError: (state: SocketState, action: PayloadAction<SocketErrorObject>) => {
			handleErrorNotification('Socket connecting error occurred.')
			return {
				...state,
				status: SocketStatus.connecting_error,
				...(action.payload.payload ? { details: JSON.stringify(action.payload.payload) } : {})
			}
		},
		socketReconnectError: (state: SocketState, action: PayloadAction<SocketErrorObject>) => {
			handleErrorNotification('Socket reconnect error occurred.')
			return {
				...state,
				status: SocketStatus.error_reconnecting,
				...(action.payload.payload ? { details: JSON.stringify(action.payload.payload) } : {})
			}
		}
	}
})

export default socketSlice

export const socketReducer = socketSlice.reducer
export const {
	socketConnected,
	socketDisconnected,
	socketConnectionTimeout,
	socketReconnected,
	socketReconnecting,
	socketError,
	socketConnectingError,
	socketReconnectError,
	socketReconnectFailed
} = socketSlice.actions
