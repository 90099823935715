import axios from 'axios'
import { AMBIENT_API_URL } from 'src/resources/constants'
import { APIAuthStatus, apiAuthenticationStatus } from './graphql/localState'

export const axiosFeedAppClient = axios.create({
	baseURL: `${AMBIENT_API_URL}/api/v1`,
	timeout: 5000
})

export interface RefreshTokenResponse {
	token: string
	refreshToken: string
}

/**
 * Exchange a Google OAuth credential for a feed auth token
 * @param credential
 * @returns
 */
export const getFeedTokenForGoogleCredential = async (code: string): Promise<RefreshTokenResponse> => {
	const { data }: { data: any } = await axiosFeedAppClient.post(
		'/auth/google',
		{ code },
		{ headers: { 'Content-Type': 'application/json' } }
	)
	return {
		token: data.token,
		refreshToken: data.refresh_token
	}
}

/**
 * Exchange a preauthorization code for a feed auth token
 * @param credential
 * @returns
 */
export const getTokenForPreauthorizationCode = async (code: string, nonce: string, sub: string): Promise<RefreshTokenResponse> => {
	const { data }: { data: any } = await axiosFeedAppClient.post(
		'/auth/preauthorized',
		{ code, nonce, sub },
		{ headers: { 'Content-Type': 'application/json' } }
	)
	return {
		token: data?.tokens?.token,
		refreshToken: data?.tokens?.refresh_token
	}
}


// cache the refresh token promise to prevent multiple refreshes
let refreshTokenPromise: Promise<RefreshTokenResponse> | null = null

export const refreshAmbientAuthToken = async (token: string, refreshToken: string): Promise<RefreshTokenResponse> => {
	// if there is already a refresh in progress, return it
	if (refreshTokenPromise) {
		return refreshTokenPromise
	}

	apiAuthenticationStatus(APIAuthStatus.REFRESHING)
	// otherwise, make a new refresh request, cache it, and return it
	refreshTokenPromise = axiosFeedAppClient.post(
		'/auth/refresh',
		{ token, refresh_info: refreshToken },
		{ headers: { 'Content-Type': 'application/json' } }
	)
		.then(({ data }) => {
			apiAuthenticationStatus(APIAuthStatus.AUTHENTICATED)
			return {
				token: data.token,
				refreshToken: data.refresh_token
			}
		})
		// if the refresh fails, set the auth status to error so the user can re-auth
		.catch((err) => {
			console.log(err)
			if (err.response.status === 401) {
				apiAuthenticationStatus(APIAuthStatus.ERROR)
			}
			return Promise.reject(err)
		})
		// clear the refresh promise cache when the request is complete
		.finally(() => {
			refreshTokenPromise = null
		})

	return refreshTokenPromise
}
