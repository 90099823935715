// for methods that can't use react hooks
export const isBrowser = !navigator.userAgent.toLowerCase().includes('zoom')

/**
 * A hook indicating whether the app is running in the browser
 * @param url
 */
const useBrowser = (): boolean => {
	return isBrowser
}

export default useBrowser
