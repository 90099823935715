import { useApolloClient, useReactiveVar } from "@apollo/client"
import { useEffect } from "react"
import { ConnectionStatus, subscriptionConnectionStatus } from "./graphql/localState"

const ApolloClientConnectionMonitor = () => {
  const client = useApolloClient()
  const connectionStatus = useReactiveVar(subscriptionConnectionStatus)

  // when the window gains focus, make sure the apollo websocket connection is open
  useEffect(() => {
    if (connectionStatus === ConnectionStatus.RECONNECTED) {
      client.reFetchObservableQueries()
    }
  }, [client, connectionStatus])

  return <></>
}

export default ApolloClientConnectionMonitor