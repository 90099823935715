import { AnyAction, combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit'
import { participantsReducer } from './slices/participantsSlice'
import { userReducer } from './slices/userSlice'
import { userMatchmakingReducer } from './slices/userMatchmakingSlice'
import { zoomReducer } from './slices/zoomSlice'
import { meetingReducer } from './slices/meetingSlice'
import { matchmakingReducer } from './slices/matchmakingSlice'
import { meetingInfoReducer } from './slices/meetingInfoSlice'
import { socketReducer } from './slices/socketSlice'
import { mapReducer } from './slices/mapSlice'
import { sessionReducer } from './slices/sessionSlice'
import sentryReduxEnhancer from './enhancers/sentryReduxEnhancer'
import { meetingConnectionReducer } from './slices/meetingConnectionSlice'
import { LOGOUT_ACTION } from './actions'

const combinedReducers = combineReducers({
	session: sessionReducer,
	user: userReducer,
	userMatchmaking: userMatchmakingReducer,
	socket: socketReducer,
	map: mapReducer,
	matchmaking: matchmakingReducer,
	meeting: meetingReducer,
	meetingInfo: meetingInfoReducer,
	participants: participantsReducer,
	zoom: zoomReducer,
	meetingConnectivity: meetingConnectionReducer
})

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
	// clear out the state on logout
	if (action.type === LOGOUT_ACTION.type) {
		state = {} as RootState
	}

	return combinedReducers(state, action)
}

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
	return configureStore({
		reducer: rootReducer,
		enhancers: [sentryReduxEnhancer],
		preloadedState
	})
}

export const rootStore = configureStore<RootState>({
	reducer: rootReducer,
	enhancers: [sentryReduxEnhancer]
})

export type RootState = ReturnType<typeof combinedReducers>
export type AppDispatch = typeof rootStore.dispatch

export default rootStore
