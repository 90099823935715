import React from 'react'
import ReactLinkify from 'react-linkify'

interface LinkifyProps {}

export const Linkify: React.FC<LinkifyProps> = ({ children }) => {
	const componentDecorator = (href: any, text: string, key: any) => (
		<a href={href} key={key} target='_blank' rel='noreferrer'>
			{text}
		</a>
	)

	return <ReactLinkify componentDecorator={componentDecorator}>{children}</ReactLinkify>
}
