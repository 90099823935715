import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
	ConfigResponse,
	GetMeetingContextResponse,
	GetMeetingUUIDResponse,
	GetUserContextResponse,
	OnMyUserContextChangeEvent,
	Participant as ZoomParticipant
} from '@zoom/appssdk'

export interface ZoomState {
	zoomSDKConfig?: ConfigResponse | null
	zoomMeetingUUID?: GetMeetingUUIDResponse | null
	zoomMeetingContext?: GetMeetingContextResponse | null
	zoomMeetingUrl?: string | null
	zoomUserContext?: GetUserContextResponse | null
	zoomParticipants?: ZoomParticipant[] | null
	supportedJsApis?: string[]
	onAuthorizedFailed: boolean
}

export const initialState: ZoomState = {
	onAuthorizedFailed: false,
}

export const zoomSlice = createSlice({
	name: 'zoom',
	initialState,
	reducers: {
		setZoomSDKConfig: (state: ZoomState, action: PayloadAction<ConfigResponse | null>) => {
			return { ...state, zoomSDKConfig: action.payload }
		},
		setZoomMeetingUrl: (state: ZoomState, action: PayloadAction<string | null>) => {
			return { ...state, zoomMeetingUrl: action.payload }
		},
		setZoomMeetingUUID: (state: ZoomState, action: PayloadAction<GetMeetingUUIDResponse>) => {
			return { ...state, zoomMeetingUUID: action.payload }
		},
		setZoomMeetingContext: (state: ZoomState, action: PayloadAction<GetMeetingContextResponse>) => {
			return { ...state, zoomMeetingContext: action.payload }
		},
		setZoomUserContext: (state: ZoomState, action: PayloadAction<GetUserContextResponse>) => {
			return { ...state, zoomUserContext: action.payload }
		},
		updateZoomUserContext: (state: ZoomState, action: PayloadAction<OnMyUserContextChangeEvent>) => {
			// if the user context has not been set, it cannot be updated
			if (!state.zoomUserContext) {
				return { ...state }
			}

			const newZoomUserContext = {
				...state.zoomUserContext,
				...action.payload
			}
			return { ...state, zoomUserContext: newZoomUserContext }
		},
		setZoomParticipants: (state: ZoomState, action: PayloadAction<ZoomParticipant[]>) => {
			return { ...state, zoomParticipants: action.payload }
		},
		addZoomParticipant: (state: ZoomState, action: PayloadAction<ZoomParticipant>) => {
			const newParticipant: ZoomParticipant = action.payload
			if (state.zoomParticipants === null || state.zoomParticipants === undefined) {
				return {
					...state,
					zoomParticipants: [newParticipant]
				}
			}
			return {
				...state,
				zoomParticipants: [...state.zoomParticipants, newParticipant]
			}
		},
		removeZoomParticipant: (state: ZoomState, action: PayloadAction<string>) => {
			const participantId = action.payload.toString()
			if (state.zoomParticipants !== undefined && state.zoomParticipants !== null) {
				const newParticipants = state.zoomParticipants.filter(
					(p: ZoomParticipant) => p.participantId !== participantId
				)
				return {
					...state,
					zoomParticipants: newParticipants
				}
			}
		},
		setSupportedZoomJsAPIs: (state: ZoomState, action: PayloadAction<string[]>) => {
			return { ...state, supportedJsApis: action.payload }
		},
		setOnAuthorizedFailed: (state: ZoomState, action: PayloadAction<boolean>) => {
			return { ...state, onAuthorizedFailed: action.payload }
		}
	}
})

export default zoomSlice

export const zoomReducer = zoomSlice.reducer
export const {
	setZoomSDKConfig,
	setZoomMeetingUUID,
	setZoomMeetingUrl,
	setZoomMeetingContext,
	setZoomUserContext,
	updateZoomUserContext,
	setZoomParticipants,
	addZoomParticipant,
	removeZoomParticipant,
	setSupportedZoomJsAPIs,
	setOnAuthorizedFailed
} = zoomSlice.actions
