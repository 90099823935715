import styled from 'styled-components'
import ClearIcon from '@mui/icons-material/Clear'

interface Props {
	onDelete?: () => void
	children: string
	disabled?: boolean
}

const EditableTag = ({ onDelete, children, disabled = false }: Props) => (
	<StyledEditableTag>
		<TagContainer>
			<TagTitle>{children}</TagTitle>
			{!disabled && (
				<DeleteButton type='button' onClick={onDelete}>
					<ClearIcon fontSize='inherit' />
				</DeleteButton>
			)}
		</TagContainer>
	</StyledEditableTag>
)

const TagTitle = styled.span`
	color: #212b36;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: center;
`

const TagContainer = styled.div`
	padding: 2px 0;
	color: #212b36;
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0.15px;
	display: flex;
	flex-direction: row;
	align-items: center;
`

const DeleteButton = styled.button`
	border: none;
	background: rgb(175, 177, 183);
	color: rgb(232, 235, 238);
	padding: 0;
	margin: 0 0 0 8.5px;
	line-height: 1;
	border-radius: 50%;
	width: 15px;
	height: 15px;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		cursor: pointer;
	}
`

interface StyledEditableTagProps {
	disabled?: boolean
}

const StyledEditableTag = styled.div<StyledEditableTagProps>`
	display: inline-block;
	margin: 3px;
	padding: 5px 12.5px;
	border-radius: 24px;
	background-color: rgba(145, 158, 171, 0.16);
`

export default EditableTag
