/**
 * A singleton for managing analytics.
 */
import Mixpanel from 'mixpanel-browser'
import { AnalyticsEvent, AnalyticsUserIdentificationData, RegisterData } from './types'
import ErrorsManager from '../ErrorsManager'

class AnalyticsManager {
	constructor() {
		const token = process.env.REACT_APP_MIXPANEL_TOKEN
		if (token) {
			Mixpanel.init(token, { debug: process.env.REACT_APP_MIXPANEL_DEBUG === 'true' })
		} else {
			// iterate overall method names
			Object.getOwnPropertyNames(AnalyticsManager.prototype).forEach((name: string) => {
				// replace each method with an empty function
				AnalyticsManager.prototype[name] = function () {
					return
				}
			})
		}
	}

	identify(user_id: string, data?: AnalyticsUserIdentificationData) {
		try {
			Mixpanel.identify(user_id)
			if (data) this.addDataToCurrentUser(data)
		} catch (err) {
			ErrorsManager.track(err, '[AnalyticsManager] - Failed to add identify user')
		}
	}

	register(data: RegisterData) {
		try {
			Mixpanel.register(data)
		} catch (err) {
			ErrorsManager.track(err, '[AnalyticsManager] - Failed to register super properties', data)
		}
	}

	addDataToCurrentUser(data: { [key: string]: any }) {
		try {
			if (data.email) {
				const { email, ...otherFields } = data
				Mixpanel.people.set({ ...otherFields, $email: data.email })
			} else {
				Mixpanel.people.set(data)
			}
		} catch (err) {
			ErrorsManager.track(err, '[AnalyticsManager] - Failed to add data to user', data)
		}
	}

	track<K extends keyof AnalyticsEvent>(event: K, data?: AnalyticsEvent[K]) {
		try {
			Mixpanel.track(event, data)
		} catch (err) {
			ErrorsManager.track(err, `[AnalyticsManager] - Failed to track event ${event} with data:`, data)
		}
	}

	time_event<K extends keyof AnalyticsEvent>(event: K) {
		try {
			Mixpanel.time_event(event)
		} catch (err) {
			ErrorsManager.track(err, '[AnalyticsManager] - Failed to time event')
		}
	}

	reset() {
		Mixpanel.reset()
	}
}

const analyticsManager = new AnalyticsManager()

export default analyticsManager
