import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum MeetingConnectionStatus {
	init = 'init',
	joining = 'joining',
	joined = 'joined',
	left = 'left',
}

export interface MeetingConnectionState {
	prevStatus: MeetingConnectionStatus
	status: MeetingConnectionStatus
}

export const initialState: MeetingConnectionState = {
	status: MeetingConnectionStatus.init,
	prevStatus: MeetingConnectionStatus.init
}

export const meetingConnectionSlice = createSlice({
	name: 'meetingConnection',
	initialState,
	reducers: {
		transitionToStatus: (state: MeetingConnectionState, action: PayloadAction<MeetingConnectionStatus>) => {
			return {
				...state,
				// record previous state
				prevStatus: state.status,
				// set new state
				status: action.payload,
			}
		},
	}
})

export default meetingConnectionSlice
export const meetingConnectionReducer = meetingConnectionSlice.reducer
export const {
	transitionToStatus,
} = meetingConnectionSlice.actions
