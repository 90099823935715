import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MeetingInfo } from 'src/models/meeting'

export enum MeetingStatus {
	premeeting = 'premeeting',
	active = 'active',
	stopped = 'stopped'
}

export interface MeetingInfoState {
	meetingInfo: MeetingInfo
	status: MeetingStatus
}

export const initialState: MeetingInfoState = {
	meetingInfo: {
		id: 0,
		meetingId: '',
		activityActive: 'false'
	},
	status: MeetingStatus.active
}

export const meetingInfoSlice = createSlice({
	name: 'meetingInfo',
	initialState,
	reducers: {
		setMeetingInfo: (state: MeetingInfoState, action: PayloadAction<MeetingInfo>) => {
			let newMeetingInfo = { ...action.payload }

			if (!newMeetingInfo.activityActive) {
				newMeetingInfo.activityActive = state.meetingInfo.activityActive
			}
			if (!newMeetingInfo.id) {
				newMeetingInfo.id = state.meetingInfo.id
			}
			if (!newMeetingInfo.meetingId) {
				newMeetingInfo.meetingId = state.meetingInfo.meetingId
			}
			return {
				...state,
				meetingInfo: newMeetingInfo
			}
		},
		resetMeetingInfo: (state: MeetingInfoState) => {
			let newMeetingInfo = { ...initialState.meetingInfo }

			// do not overwrite these fields, if they exist
			if (state.meetingInfo.meetingId) {
				newMeetingInfo.meetingId = state.meetingInfo.meetingId
			}
			if (state.meetingInfo.hostId) {
				newMeetingInfo.hostId = state.meetingInfo.hostId
			}
			if (state.meetingInfo.id) {
				newMeetingInfo.id = state.meetingInfo.id
			}

			return {
				...initialState,
				meetingInfo: newMeetingInfo
			}
		},
		setMeetingStatus: (state: MeetingInfoState, action: PayloadAction<MeetingStatus>) => {
			return { ...state, status: action.payload }
		},
		startActivity: (state: MeetingInfoState) => {
			state.meetingInfo.activityActive = 'true'
		},
		stopActivity: (state: MeetingInfoState) => {
			state.meetingInfo.activityActive = 'false'
		}
	}
})

export default meetingInfoSlice
export const meetingInfoReducer = meetingInfoSlice.reducer
export const {
	startActivity,
	stopActivity,
	setMeetingInfo,
	setMeetingStatus,
	resetMeetingInfo
} = meetingInfoSlice.actions
