import { Colors } from 'src/styling'
import styled from 'styled-components'

export const FormFieldContainer = styled.div`
	margin-bottom: 12px;
	display: flex;
	flex-direction: column;
	width: 100%;
`

interface FormFieldTitleProps {
	disabled?: boolean
}

export const FormFieldTitle = styled.h2<FormFieldTitleProps>`
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: ${Colors.DARK};
	margin: 0 0 5px 0;
	display: inline-block;

	${({ disabled }) => disabled && `color: rgba(0, 0, 0, 0.38);`}
`

export const FormFieldSubtitle = styled.div`
	font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #637381;
`
